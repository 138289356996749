
.product-detail-media-gallery-modal-container {
    padding: 20px 110px !important
}
.product-detail-media-gallery-modal {
	width: 100%;

	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 15px;

	.image-container {
		min-height: 200px;

		.image {
			width: 100%;
			height: 100%;
			cursor: default;
		}
	}
}

.btn-close {
    position fixed;
    top: 20px;
    right: 50px;

    width: 40px;
    height: 40px;

    background: transparent;
    border: 1px solid rgba(#121212, 0.1);
    border-radius: 50% !important;

    &:hover {
        .icon-close {
            color: rgba(0,0,0,0.72)
        }
    }

    &:focus {
        outline: .125rem solid #0071e3;
        outline-offset: .125rem;
        outline-offset: .125rem;
    }

    .icon-close {
        color: rgba(18,18,18,0.25)
    }


}
